import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl} from '@angular/forms';
import {latLng} from 'leaflet';
import {PoiService} from '../../core/services/poi.service';
import {MapService} from '../../core/services/map.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  form: FormGroup;

  filtersData = [
    {label: 'Banks & Post offices', category: 'common_services', icon: 'common_services'},
    {label: 'Grocery Stores', category: 'essential_shopping', icon: 'store'},
    {label: 'Libraries & Museums', category: 'food_and_entertainment', icon: 'library'},
    {label: 'Schools & Kindergardens', category: 'education', icon: 'education'},
    {label: 'Pharmacies & Doctors', category: 'healthcare', icon: 'healthcare'},
    {label: 'Gyms & Parks', category: 'leisure_and_sports', icon: 'sports'}
  ];

  @Output()
    filterSelected = new EventEmitter<any>();

  pois!: any[];

  constructor(private formBuilder: FormBuilder,
              private poiService: PoiService,
              private mapService: MapService) {
    this.form = this.formBuilder.group({
      filters: new FormArray([])
    });
    this.addCheckboxes();
  }

  ngOnInit(): void {
    this.fetchPOIs().then((res: any[]) => {
      this.form.valueChanges
        .subscribe(data => {
          const selectedFilters = this.form.value.filters
            .map((checked: any, index: number) => checked ? this.filtersData[index].category : null)
            .filter((filter: any) => filter !== null);
          this.drawPOIsOnMap(selectedFilters);
          this.filterSelected.emit(selectedFilters);
        });
    });
  }

  get filtersFormArray(): any {
    return this.form.controls.filters as FormArray;
  }

  private addCheckboxes(): void {
    this.filtersData.forEach(() => this.filtersFormArray.push(new FormControl(false)));
  }

  private fetchPOIs(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.poiService.load().subscribe((res: any) => {
        if (res) {
          this.pois = res.features;
        }
        resolve(this.pois);
      }, (err: Error) => {
        reject(err);
      });
    });
  }

  private drawPOIsOnMap(categories: string[]): void {
    this.mapService.markersGroup.clearLayers();
    const pois = this.findPOICategories(categories);
    if (this.mapService.layerGroups.getLayers()) {
      pois.forEach((poi: any) => {
        this.mapService.placeMarker(poi.geometry.coordinates[1], poi.geometry.coordinates[0], poi.properties.category);
      });
    }
  }

  private findPOICategories(categories: string[]): any[] {
    let results: any[] = [];
    categories.forEach((category: string) => {
      const features = this.inBounds.filter((feature: any) => {
        if (category === feature.properties.category) {
          return feature;
        }
      });
      results = [...results, ...features];
    });
    return results;
  }

  private get inBounds(): any[] {
    const bbox = this.mapService.layerGroups.getBounds();
    return this.pois.filter((feature: any) => {
      if (bbox.contains(latLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]))) {
        return feature;
      }
    });
  }
}
