/* tslint:disable */
/**
 * Pre-defined color schemes downloaded from https://carto.com/carto-colors/
 */
export const cartocolors = {
  "Burg": {
    "2": [
      "#ffc6c4",
      "#672044"
    ],
    "3": [
      "#ffc6c4",
      "#cc607d",
      "#672044"
    ],
    "4": [
      "#ffc6c4",
      "#e38191",
      "#ad466c",
      "#672044"
    ],
    "5": [
      "#ffc6c4",
      "#ee919b",
      "#cc607d",
      "#9e3963",
      "#672044"
    ],
    "6": [
      "#ffc6c4",
      "#f29ca3",
      "#da7489",
      "#b95073",
      "#93345d",
      "#672044"
    ],
    "7": [
      "#ffc6c4",
      "#f4a3a8",
      "#e38191",
      "#cc607d",
      "#ad466c",
      "#8b3058",
      "#672044"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "BurgYl": {
    "2": [
      "#fbe6c5",
      "#70284a"
    ],
    "3": [
      "#fbe6c5",
      "#dc7176",
      "#70284a"
    ],
    "4": [
      "#fbe6c5",
      "#ee8a82",
      "#c8586c",
      "#70284a"
    ],
    "5": [
      "#fbe6c5",
      "#f2a28a",
      "#dc7176",
      "#b24b65",
      "#70284a"
    ],
    "6": [
      "#fbe6c5",
      "#f4b191",
      "#e7807d",
      "#d06270",
      "#a44360",
      "#70284a"
    ],
    "7": [
      "#fbe6c5",
      "#f5ba98",
      "#ee8a82",
      "#dc7176",
      "#c8586c",
      "#9c3f5d",
      "#70284a"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "RedOr": {
    "2": [
      "#f6d2a9",
      "#b13f64"
    ],
    "3": [
      "#f6d2a9",
      "#ea8171",
      "#b13f64"
    ],
    "4": [
      "#f6d2a9",
      "#f19c7c",
      "#dd686c",
      "#b13f64"
    ],
    "5": [
      "#f6d2a9",
      "#f3aa84",
      "#ea8171",
      "#d55d6a",
      "#b13f64"
    ],
    "6": [
      "#f6d2a9",
      "#f4b28a",
      "#ef9177",
      "#e3726d",
      "#cf5669",
      "#b13f64"
    ],
    "7": [
      "#f6d2a9",
      "#f5b78e",
      "#f19c7c",
      "#ea8171",
      "#dd686c",
      "#ca5268",
      "#b13f64"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "OrYel": {
    "2": [
      "#ecda9a",
      "#ee4d5a"
    ],
    "3": [
      "#ecda9a",
      "#f7945d",
      "#ee4d5a"
    ],
    "4": [
      "#ecda9a",
      "#f3ad6a",
      "#f97b57",
      "#ee4d5a"
    ],
    "5": [
      "#ecda9a",
      "#f1b973",
      "#f7945d",
      "#f86f56",
      "#ee4d5a"
    ],
    "6": [
      "#ecda9a",
      "#f0c079",
      "#f5a363",
      "#f98558",
      "#f76856",
      "#ee4d5a"
    ],
    "7": [
      "#ecda9a",
      "#efc47e",
      "#f3ad6a",
      "#f7945d",
      "#f97b57",
      "#f66356",
      "#ee4d5a"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "Peach": {
    "2": [
      "#fde0c5",
      "#eb4a40"
    ],
    "3": [
      "#fde0c5",
      "#f59e72",
      "#eb4a40"
    ],
    "4": [
      "#fde0c5",
      "#f8b58b",
      "#f2855d",
      "#eb4a40"
    ],
    "5": [
      "#fde0c5",
      "#f9c098",
      "#f59e72",
      "#f17854",
      "#eb4a40"
    ],
    "6": [
      "#fde0c5",
      "#fac7a1",
      "#f7ac80",
      "#f38f65",
      "#f0704f",
      "#eb4a40"
    ],
    "7": [
      "#fde0c5",
      "#facba6",
      "#f8b58b",
      "#f59e72",
      "#f2855d",
      "#ef6a4c",
      "#eb4a40"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "PinkYl": {
    "2": [
      "#fef6b5",
      "#e15383"
    ],
    "3": [
      "#fef6b5",
      "#ffa679",
      "#e15383"
    ],
    "4": [
      "#fef6b5",
      "#ffc285",
      "#fa8a76",
      "#e15383"
    ],
    "5": [
      "#fef6b5",
      "#ffd08e",
      "#ffa679",
      "#f67b77",
      "#e15383"
    ],
    "6": [
      "#fef6b5",
      "#ffd795",
      "#ffb77f",
      "#fd9576",
      "#f37378",
      "#e15383"
    ],
    "7": [
      "#fef6b5",
      "#ffdd9a",
      "#ffc285",
      "#ffa679",
      "#fa8a76",
      "#f16d7a",
      "#e15383"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "Mint": {
    "2": [
      "#e4f1e1",
      "#0d585f"
    ],
    "3": [
      "#e4f1e1",
      "#63a6a0",
      "#0d585f"
    ],
    "4": [
      "#e4f1e1",
      "#89c0b6",
      "#448c8a",
      "#0d585f"
    ],
    "5": [
      "#E4F1E1",
      "#9CCDC1",
      "#63A6A0",
      "#337F7F",
      "#0D585F"
    ],
    "6": [
      "#e4f1e1",
      "#abd4c7",
      "#7ab5ad",
      "#509693",
      "#2c7778",
      "#0d585f"
    ],
    "7": [
      "#e4f1e1",
      "#b4d9cc",
      "#89c0b6",
      "#63a6a0",
      "#448c8a",
      "#287274",
      "#0d585f"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "BluGrn": {
    "2": [
      "#c4e6c3",
      "#1d4f60"
    ],
    "3": [
      "#c4e6c3",
      "#4da284",
      "#1d4f60"
    ],
    "4": [
      "#c4e6c3",
      "#6dbc90",
      "#36877a",
      "#1d4f60"
    ],
    "5": [
      "#c4e6c3",
      "#80c799",
      "#4da284",
      "#2d7974",
      "#1d4f60"
    ],
    "6": [
      "#c4e6c3",
      "#8dce9f",
      "#5fb28b",
      "#3e927e",
      "#297071",
      "#1d4f60"
    ],
    "7": [
      "#c4e6c3",
      "#96d2a4",
      "#6dbc90",
      "#4da284",
      "#36877a",
      "#266b6e",
      "#1d4f60"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "DarkMint": {
    "2": [
      "#d2fbd4",
      "#123f5a"
    ],
    "3": [
      "#d2fbd4",
      "#559c9e",
      "#123f5a"
    ],
    "4": [
      "#d2fbd4",
      "#7bbcb0",
      "#3a7c89",
      "#123f5a"
    ],
    "5": [
      "#d2fbd4",
      "#8eccb9",
      "#559c9e",
      "#2b6c7f",
      "#123f5a"
    ],
    "6": [
      "#d2fbd4",
      "#9cd5be",
      "#6cafa9",
      "#458892",
      "#266377",
      "#123f5a"
    ],
    "7": [
      "#d2fbd4",
      "#a5dbc2",
      "#7bbcb0",
      "#559c9e",
      "#3a7c89",
      "#235d72",
      "#123f5a"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "Emrld": {
    "2": [
      "#d3f2a3",
      "#074050"
    ],
    "3": [
      "#d3f2a3",
      "#4c9b82",
      "#074050"
    ],
    "4": [
      "#d3f2a3",
      "#6cc08b",
      "#217a79",
      "#074050"
    ],
    "5": [
      "#d3f2a3",
      "#82d091",
      "#4c9b82",
      "#19696f",
      "#074050"
    ],
    "6": [
      "#d3f2a3",
      "#8fda94",
      "#60b187",
      "#35877d",
      "#145f69",
      "#074050"
    ],
    "7": [
      "#d3f2a3",
      "#97e196",
      "#6cc08b",
      "#4c9b82",
      "#217a79",
      "#105965",
      "#074050"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "ag_GrnYl": {
    "2": [
      "#245668",
      "#EDEF5D"
    ],
    "3": [
      "#245668",
      "#39AB7E",
      "#EDEF5D"
    ],
    "4": [
      "#245668",
      "#0D8F81",
      "#6EC574",
      "#EDEF5D"
    ],
    "5": [
      "#245668",
      "#04817E",
      "#39AB7E",
      "#8BD16D",
      "#EDEF5D"
    ],
    "6": [
      "#245668",
      "#09787C",
      "#1D9A81",
      "#58BB79",
      "#9DD869",
      "#EDEF5D"
    ],
    "7": [
      "#245668",
      "#0F7279",
      "#0D8F81",
      "#39AB7E",
      "#6EC574",
      "#A9DC67",
      "#EDEF5D"
    ],
    "tags": [
      "aggregation"
    ]
  },
  "BluYl": {
    "2": [
      "#f7feae",
      "#045275"
    ],
    "3": [
      "#f7feae",
      "#46aea0",
      "#045275"
    ],
    "4": [
      "#f7feae",
      "#7ccba2",
      "#089099",
      "#045275"
    ],
    "5": [
      "#f7feae",
      "#9bd8a4",
      "#46aea0",
      "#058092",
      "#045275"
    ],
    "6": [
      "#f7feae",
      "#ace1a4",
      "#68bfa1",
      "#2a9c9c",
      "#02778e",
      "#045275"
    ],
    "7": [
      "#f7feae",
      "#b7e6a5",
      "#7ccba2",
      "#46aea0",
      "#089099",
      "#00718b",
      "#045275"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "Teal": {
    "2": [
      "#d1eeea",
      "#2a5674"
    ],
    "3": [
      "#d1eeea",
      "#68abb8",
      "#2a5674"
    ],
    "4": [
      "#d1eeea",
      "#85c4c9",
      "#4f90a6",
      "#2a5674"
    ],
    "5": [
      "#d1eeea",
      "#96d0d1",
      "#68abb8",
      "#45829b",
      "#2a5674"
    ],
    "6": [
      "#d1eeea",
      "#a1d7d6",
      "#79bbc3",
      "#599bae",
      "#3f7994",
      "#2a5674"
    ],
    "7": [
      "#d1eeea",
      "#a8dbd9",
      "#85c4c9",
      "#68abb8",
      "#4f90a6",
      "#3b738f",
      "#2a5674"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "TealGrn": {
    "2": [
      "#b0f2bc",
      "#257d98"
    ],
    "3": [
      "#b0f2bc",
      "#4cc8a3",
      "#257d98"
    ],
    "4": [
      "#b0f2bc",
      "#67dba5",
      "#38b2a3",
      "#257d98"
    ],
    "5": [
      "#b0f2bc",
      "#77e2a8",
      "#4cc8a3",
      "#31a6a2",
      "#257d98"
    ],
    "6": [
      "#b0f2bc",
      "#82e6aa",
      "#5bd4a4",
      "#3fbba3",
      "#2e9ea1",
      "#257d98"
    ],
    "7": [
      "#b0f2bc",
      "#89e8ac",
      "#67dba5",
      "#4cc8a3",
      "#38b2a3",
      "#2c98a0",
      "#257d98"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "Purp": {
    "2": [
      "#f3e0f7",
      "#63589f"
    ],
    "3": [
      "#f3e0f7",
      "#b998dd",
      "#63589f"
    ],
    "4": [
      "#f3e0f7",
      "#d1afe8",
      "#9f82ce",
      "#63589f"
    ],
    "5": [
      "#f3e0f7",
      "#dbbaed",
      "#b998dd",
      "#9178c4",
      "#63589f"
    ],
    "6": [
      "#f3e0f7",
      "#e0c2ef",
      "#c8a5e4",
      "#aa8bd4",
      "#8871be",
      "#63589f"
    ],
    "7": [
      "#f3e0f7",
      "#e4c7f1",
      "#d1afe8",
      "#b998dd",
      "#9f82ce",
      "#826dba",
      "#63589f"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "PurpOr": {
    "2": [
      "#f9ddda",
      "#573b88"
    ],
    "3": [
      "#f9ddda",
      "#ce78b3",
      "#573b88"
    ],
    "4": [
      "#f9ddda",
      "#e597b9",
      "#ad5fad",
      "#573b88"
    ],
    "5": [
      "#f9ddda",
      "#eda8bd",
      "#ce78b3",
      "#9955a8",
      "#573b88"
    ],
    "6": [
      "#f9ddda",
      "#f0b2c1",
      "#dd8ab6",
      "#bb69b0",
      "#8c4fa4",
      "#573b88"
    ],
    "7": [
      "#f9ddda",
      "#f2b9c4",
      "#e597b9",
      "#ce78b3",
      "#ad5fad",
      "#834ba0",
      "#573b88"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "Sunset": {
    "2": [
      "#f3e79b",
      "#5c53a5"
    ],
    "3": [
      "#f3e79b",
      "#eb7f86",
      "#5c53a5"
    ],
    "4": [
      "#f3e79b",
      "#f8a07e",
      "#ce6693",
      "#5c53a5"
    ],
    "5": [
      "#f3e79b",
      "#fab27f",
      "#eb7f86",
      "#b95e9a",
      "#5c53a5"
    ],
    "6": [
      "#f3e79b",
      "#fabc82",
      "#f59280",
      "#dc6f8e",
      "#ab5b9e",
      "#5c53a5"
    ],
    "7": [
      "#f3e79b",
      "#fac484",
      "#f8a07e",
      "#eb7f86",
      "#ce6693",
      "#a059a0",
      "#5c53a5"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "Magenta": {
    "2": [
      "#f3cbd3",
      "#6c2167"
    ],
    "3": [
      "#f3cbd3",
      "#ca699d",
      "#6c2167"
    ],
    "4": [
      "#f3cbd3",
      "#dd88ac",
      "#b14d8e",
      "#6c2167"
    ],
    "5": [
      "#f3cbd3",
      "#e498b4",
      "#ca699d",
      "#a24186",
      "#6c2167"
    ],
    "6": [
      "#f3cbd3",
      "#e7a2b9",
      "#d67ba5",
      "#bc5894",
      "#983a81",
      "#6c2167"
    ],
    "7": [
      "#f3cbd3",
      "#eaa9bd",
      "#dd88ac",
      "#ca699d",
      "#b14d8e",
      "#91357d",
      "#6c2167"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "SunsetDark": {
    "2": [
      "#fcde9c",
      "#7c1d6f"
    ],
    "3": [
      "#fcde9c",
      "#e34f6f",
      "#7c1d6f"
    ],
    "4": [
      "#fcde9c",
      "#f0746e",
      "#dc3977",
      "#7c1d6f"
    ],
    "5": [
      "#fcde9c",
      "#f58670",
      "#e34f6f",
      "#d72d7c",
      "#7c1d6f"
    ],
    "6": [
      "#fcde9c",
      "#f89872",
      "#ec666d",
      "#df4273",
      "#c5287b",
      "#7c1d6f"
    ],
    "7": [
      "#fcde9c",
      "#faa476",
      "#f0746e",
      "#e34f6f",
      "#dc3977",
      "#b9257a",
      "#7c1d6f"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "ag_Sunset": {
    "2": [
      "#4b2991",
      "#edd9a3"
    ],
    "3": [
      "#4b2991",
      "#ea4f88",
      "#edd9a3"
    ],
    "4": [
      "#4b2991",
      "#c0369d",
      "#fa7876",
      "#edd9a3"
    ],
    "5": [
      "#4b2991",
      "#a52fa2",
      "#ea4f88",
      "#fa9074",
      "#edd9a3"
    ],
    "6": [
      "#4b2991",
      "#932da3",
      "#d43f96",
      "#f7667c",
      "#f89f77",
      "#edd9a3"
    ],
    "7": [
      "#4b2991",
      "#872ca2",
      "#c0369d",
      "#ea4f88",
      "#fa7876",
      "#f6a97a",
      "#edd9a3"
    ],
    "tags": [
      "aggregation"
    ]
  },
  "BrwnYl": {
    "2": [
      "#ede5cf",
      "#541f3f"
    ],
    "3": [
      "#ede5cf",
      "#c1766f",
      "#541f3f"
    ],
    "4": [
      "#ede5cf",
      "#d39c83",
      "#a65461",
      "#541f3f"
    ],
    "5": [
      "#ede5cf",
      "#daaf91",
      "#c1766f",
      "#95455a",
      "#541f3f"
    ],
    "6": [
      "#ede5cf",
      "#ddba9b",
      "#cd8c7a",
      "#b26166",
      "#8a3c56",
      "#541f3f"
    ],
    "7": [
      "#ede5cf",
      "#e0c2a2",
      "#d39c83",
      "#c1766f",
      "#a65461",
      "#813753",
      "#541f3f"
    ],
    "tags": [
      "quantitative"
    ]
  },
  "ArmyRose": {
    "2": [
      "#929b4f",
      "#db8195"
    ],
    "3": [
      "#a3ad62",
      "#fdfbe4",
      "#df91a3"
    ],
    "4": [
      "#929b4f",
      "#d9dbaf",
      "#f3d1ca",
      "#db8195"
    ],
    "5": [
      "#879043",
      "#c1c68c",
      "#fdfbe4",
      "#ebb4b8",
      "#d8758b"
    ],
    "6": [
      "#7f883b",
      "#b0b874",
      "#e3e4be",
      "#f6ddd1",
      "#e4a0ac",
      "#d66d85"
    ],
    "7": [
      "#798234",
      "#a3ad62",
      "#d0d3a2",
      "#fdfbe4",
      "#f0c6c3",
      "#df91a3",
      "#d46780"
    ],
    "tags": [
      "diverging"
    ]
  },
  "Fall": {
    "2": [
      "#3d5941",
      "#ca562c"
    ],
    "3": [
      "#3d5941",
      "#f6edbd",
      "#ca562c"
    ],
    "4": [
      "#3d5941",
      "#b5b991",
      "#edbb8a",
      "#ca562c"
    ],
    "5": [
      "#3d5941",
      "#96a07c",
      "#f6edbd",
      "#e6a272",
      "#ca562c"
    ],
    "6": [
      "#3d5941",
      "#839170",
      "#cecea2",
      "#f1cf9e",
      "#e19464",
      "#ca562c"
    ],
    "7": [
      "#3d5941",
      "#778868",
      "#b5b991",
      "#f6edbd",
      "#edbb8a",
      "#de8a5a",
      "#ca562c"
    ],
    "tags": [
      "diverging"
    ]
  },
  "Geyser": {
    "2": [
      "#008080",
      "#ca562c"
    ],
    "3": [
      "#008080",
      "#f6edbd",
      "#ca562c"
    ],
    "4": [
      "#008080",
      "#b4c8a8",
      "#edbb8a",
      "#ca562c"
    ],
    "5": [
      "#008080",
      "#92b69e",
      "#f6edbd",
      "#e6a272",
      "#ca562c"
    ],
    "6": [
      "#008080",
      "#7eab98",
      "#ced7b1",
      "#f1cf9e",
      "#e19464",
      "#ca562c"
    ],
    "7": [
      "#008080",
      "#70a494",
      "#b4c8a8",
      "#f6edbd",
      "#edbb8a",
      "#de8a5a",
      "#ca562c"
    ],
    "tags": [
      "diverging"
    ]
  },
  "Temps": {
    "2": [
      "#009392",
      "#cf597e"
    ],
    "3": [
      "#009392",
      "#e9e29c",
      "#cf597e"
    ],
    "4": [
      "#009392",
      "#9ccb86",
      "#eeb479",
      "#cf597e"
    ],
    "5": [
      "#009392",
      "#71be83",
      "#e9e29c",
      "#ed9c72",
      "#cf597e"
    ],
    "6": [
      "#009392",
      "#52b684",
      "#bcd48c",
      "#edc783",
      "#eb8d71",
      "#cf597e"
    ],
    "7": [
      "#009392",
      "#39b185",
      "#9ccb86",
      "#e9e29c",
      "#eeb479",
      "#e88471",
      "#cf597e"
    ],
    "tags": [
      "diverging"
    ]
  },
  "TealRose": {
    "2": [
      "#009392",
      "#d0587e"
    ],
    "3": [
      "#009392",
      "#f1eac8",
      "#d0587e"
    ],
    "4": [
      "#009392",
      "#91b8aa",
      "#f1eac8",
      "#dfa0a0",
      "#d0587e"
    ],
    "5": [
      "#009392",
      "#91b8aa",
      "#f1eac8",
      "#dfa0a0",
      "#d0587e"
    ],
    "6": [
      "#009392",
      "#72aaa1",
      "#b1c7b3",
      "#e5b9ad",
      "#d98994",
      "#d0587e"
    ],
    "7": [
      "#009392",
      "#72aaa1",
      "#b1c7b3",
      "#f1eac8",
      "#e5b9ad",
      "#d98994",
      "#d0587e"
    ],
    "tags": [
      "diverging"
    ]
  },
  "Tropic": {
    "2": [
      "#009B9E",
      "#C75DAB"
    ],
    "3": [
      "#009B9E",
      "#F1F1F1",
      "#C75DAB"
    ],
    "4": [
      "#009B9E",
      "#A7D3D4",
      "#E4C1D9",
      "#C75DAB"
    ],
    "5": [
      "#009B9E",
      "#7CC5C6",
      "#F1F1F1",
      "#DDA9CD",
      "#C75DAB"
    ],
    "6": [
      "#009B9E",
      "#5DBCBE",
      "#C6DFDF",
      "#E9D4E2",
      "#D99BC6",
      "#C75DAB"
    ],
    "7": [
      "#009B9E",
      "#42B7B9",
      "#A7D3D4",
      "#F1F1F1",
      "#E4C1D9",
      "#D691C1",
      "#C75DAB"
    ],
    "tags": [
      "diverging"
    ]
  },
  "Earth": {
    "2": [
      "#A16928",
      "#2887a1"
    ],
    "3": [
      "#A16928",
      "#edeac2",
      "#2887a1"
    ],
    "4": [
      "#A16928",
      "#d6bd8d",
      "#b5c8b8",
      "#2887a1"
    ],
    "5": [
      "#A16928",
      "#caa873",
      "#edeac2",
      "#98b7b2",
      "#2887a1"
    ],
    "6": [
      "#A16928",
      "#c29b64",
      "#e0cfa2",
      "#cbd5bc",
      "#85adaf",
      "#2887a1"
    ],
    "7": [
      "#A16928",
      "#bd925a",
      "#d6bd8d",
      "#edeac2",
      "#b5c8b8",
      "#79a7ac",
      "#2887a1"
    ],
    "tags": [
      "diverging"
    ]
  },
  "Antique": {
    "2": [
      "#855C75",
      "#D9AF6B",
      "#7C7C7C"
    ],
    "3": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#7C7C7C"
    ],
    "4": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#7C7C7C"
    ],
    "5": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#7C7C7C"
    ],
    "6": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#7C7C7C"
    ],
    "7": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#68855C",
      "#7C7C7C"
    ],
    "8": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#68855C",
      "#9C9C5E",
      "#7C7C7C"
    ],
    "9": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#68855C",
      "#9C9C5E",
      "#A06177",
      "#7C7C7C"
    ],
    "10": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#68855C",
      "#9C9C5E",
      "#A06177",
      "#8C785D",
      "#7C7C7C"
    ],
    "11": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#68855C",
      "#9C9C5E",
      "#A06177",
      "#8C785D",
      "#467378",
      "#7C7C7C"
    ],
    "tags": [
      "qualitative"
    ]
  },
  "Bold": {
    "2": [
      "#7F3C8D",
      "#11A579",
      "#A5AA99"
    ],
    "3": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#A5AA99"
    ],
    "4": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#A5AA99"
    ],
    "5": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#A5AA99"
    ],
    "6": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#A5AA99"
    ],
    "7": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#E68310",
      "#A5AA99"
    ],
    "8": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#E68310",
      "#008695",
      "#A5AA99"
    ],
    "9": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#E68310",
      "#008695",
      "#CF1C90",
      "#A5AA99"
    ],
    "10": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#E68310",
      "#008695",
      "#CF1C90",
      "#f97b72",
      "#A5AA99"
    ],
    "11": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#E68310",
      "#008695",
      "#CF1C90",
      "#f97b72",
      "#4b4b8f",
      "#A5AA99"
    ],
    "tags": [
      "qualitative"
    ]
  },
  "Pastel": {
    "2": [
      "#66C5CC",
      "#F6CF71",
      "#B3B3B3"
    ],
    "3": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#B3B3B3"
    ],
    "4": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#B3B3B3"
    ],
    "5": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#B3B3B3"
    ],
    "6": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#B3B3B3"
    ],
    "7": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#FE88B1",
      "#B3B3B3"
    ],
    "8": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#FE88B1",
      "#C9DB74",
      "#B3B3B3"
    ],
    "9": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#FE88B1",
      "#C9DB74",
      "#8BE0A4",
      "#B3B3B3"
    ],
    "10": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#FE88B1",
      "#C9DB74",
      "#8BE0A4",
      "#B497E7",
      "#B3B3B3"
    ],
    "11": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#FE88B1",
      "#C9DB74",
      "#8BE0A4",
      "#B497E7",
      "#D3B484",
      "#B3B3B3"
    ],
    "tags": [
      "qualitative"
    ]
  },
  "Prism": {
    "2": [
      "#5F4690",
      "#1D6996",
      "#666666"
    ],
    "3": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#666666"
    ],
    "4": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#666666"
    ],
    "5": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#666666"
    ],
    "6": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#666666"
    ],
    "7": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#E17C05",
      "#666666"
    ],
    "8": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#E17C05",
      "#CC503E",
      "#666666"
    ],
    "9": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#E17C05",
      "#CC503E",
      "#94346E",
      "#666666"
    ],
    "10": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#E17C05",
      "#CC503E",
      "#94346E",
      "#6F4070",
      "#666666"
    ],
    "11": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#E17C05",
      "#CC503E",
      "#94346E",
      "#6F4070",
      "#994E95",
      "#666666"
    ],
    "tags": [
      "qualitative"
    ]
  },
  "Safe": {
    "2": [
      "#88CCEE",
      "#CC6677",
      "#888888"
    ],
    "3": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#888888"
    ],
    "4": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#888888"
    ],
    "5": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#888888"
    ],
    "6": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#888888"
    ],
    "7": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#44AA99",
      "#888888"
    ],
    "8": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#44AA99",
      "#999933",
      "#888888"
    ],
    "9": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#44AA99",
      "#999933",
      "#882255",
      "#888888"
    ],
    "10": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#44AA99",
      "#999933",
      "#882255",
      "#661100",
      "#888888"
    ],
    "11": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#44AA99",
      "#999933",
      "#882255",
      "#661100",
      "#6699CC",
      "#888888"
    ],
    "tags": [
      "qualitative",
      "colorblind"
    ]
  },
  "Vivid": {
    "2": [
      "#E58606",
      "#5D69B1",
      "#A5AA99"
    ],
    "3": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#A5AA99"
    ],
    "4": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#A5AA99"
    ],
    "5": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#A5AA99"
    ],
    "6": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#A5AA99"
    ],
    "7": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#DAA51B",
      "#A5AA99"
    ],
    "8": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#DAA51B",
      "#2F8AC4",
      "#A5AA99"
    ],
    "9": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#DAA51B",
      "#2F8AC4",
      "#764E9F",
      "#A5AA99"
    ],
    "10": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#DAA51B",
      "#2F8AC4",
      "#764E9F",
      "#ED645A",
      "#A5AA99"
    ],
    "11": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#DAA51B",
      "#2F8AC4",
      "#764E9F",
      "#ED645A",
      "#CC3A8E",
      "#A5AA99"
    ],
    "tags": [
      "qualitative"
    ]
  }
};
