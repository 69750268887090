import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonComponent} from './button.component';


@NgModule({
  declarations: [ButtonComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild({})
  ],
  exports: [ButtonComponent]
})
export class ButtonModule { }
