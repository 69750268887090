import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private formID = 285;

  constructor(private httpClient: HttpClient) { }

  sendMail(data: any) {
    return this.httpClient.post(this.contactApi, data);
  }

  get contactApi(): string {
    return `https://triply.at/tickets/wp-json/contact-form-7/v1/contact-forms/${this.formID}/feedback`;
  }
}
