import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss']
})
export class TextBlockComponent implements OnInit {

  /**
   * Block Background
   *
   * @required
   */
  @Input()
    hasBackground = false;

  /**
   * Block Heading
   *
   * @required
   */
  @Input()
    heading = 'Welcome';

  /**
   * Block Content
   *
   */
  @Input()
    content?: string;

  /**
   * Score
   *
   */
  @Input()
    address?: any;

  constructor() { }

  ngOnInit(): void {
    if (this.address) {
      this.address.plzCode = 4040;
    }
  }

}
