import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ContactService} from '../../core/services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  /**
   * Block Background
   *
   * @required
   */
  @Input()
    fullWidth = true;

  contactForm!: FormGroup;
  submitted = false;
  mailSent = false;
  formData: any;

  constructor(private fb: FormBuilder,
              private contactService: ContactService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.contactForm = this.fb.group({
      firstname: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required,
        Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]],
      work: ['', Validators.required],
      state: ['', Validators.required],
      company: ['', Validators.required],
      message: ['']
    });
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }

    this.formData = this.contactForm.getRawValue();
    this.contactService.sendMail(this.formData).subscribe(() => {
      this.mailSent = true;
    });
  }
}
