import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { Routes, RouterModule } from  '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ContactComponent} from './contact.component';
import {ButtonModule} from '../button/button.module';

const routes: Routes = [
  { path: '', component: ContactComponent },
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    TranslateModule.forChild({}),
    ButtonModule
  ],
  exports: [RouterModule, ContactComponent],
  declarations: [
    ContactComponent
  ]
})
export class ContactModule { }
