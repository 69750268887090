import {tileLayer} from 'leaflet';

export class MapConfig {
  static tileLayerWithoutAttribution() {
    return tileLayer(
      'https://api.mapbox.com/styles/v1/tuesd4y/cjxvqa1v8007k1ctbxf7rc6kr/tiles/256/{z}/{x}/{y}@2x?' +
      'access_token=pk.eyJ1IjoidHVlc2Q0eSIsImEiOiJjanQ5eTY0a3EwNWpsNDNxZGJyOGRycWlhIn0.tR_x6qGc3-MrQL8m_pH4CA', {
        tileSize: 512,
        zoomOffset: -1,
      });
  }

  static tileLayerWithAttribution() {
    return tileLayer(
      'https://api.mapbox.com/styles/v1/tuesd4y/cjxvqa1v8007k1ctbxf7rc6kr/tiles/256/{z}/{x}/{y}@2x?' +
      'access_token=pk.eyJ1IjoidHVlc2Q0eSIsImEiOiJjanQ5eTY0a3EwNWpsNDNxZGJyOGRycWlhIn0.tR_x6qGc3-MrQL8m_pH4CA', {
        tileSize: 512,
        zoomOffset: -1,
        attribution: '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> © ' +
          '<a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      });
  }
}
