import {Component, Input, OnInit} from '@angular/core';
import {scoreToColor} from '../../core/helpers/color';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit {
  /**
   * Score content
   *
   * @required
   */
  @Input()
  score!: number;

  /**
   * How large should the button be?
   */
  @Input()
  size = 'medium';

  /**
   * Does the marker has a border?
   *
   * @Optional
   */
  @Input()
  hasBorder = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  /**
   * What is the color of the score marker ?
   */
  get color(): string {
    let _score = this.score;
    if (this.score) {
      if (_score < 10) {
        _score = _score * 10;
      }
      return scoreToColor(_score);
    }
    return '';
  }
}
