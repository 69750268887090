import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  /**
   * Button contents
   *
   * @required
   */
  @Input()
    text = 'Button';

  /**
   * Optional Href handler
   */
  @Input()
    href?: string;

  /**
   * What style to use
   */
  @Input()
    hasBackground = false;

  /**
   * How large should the button be?
   */
  @Input()
    anchor = false;

  /**
   * Optional click handler
   */
  @Output()
    onClick = new EventEmitter<Event>();

  constructor() {
  }

  ngOnInit(): void {
  }
}
