import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toggle-container',
  templateUrl: './toggle-container.component.html',
  styleUrls: ['./toggle-container.component.scss']
})
export class ToggleContainerComponent implements OnInit {

  toggleButtons = [
    {label: 'Day', time: '7:00 – 19:00'},
    {label: 'Night', time: '19:00 – 6:00'}
  ];

  activeMode = 'Day';
  @Output()
    onModeChange = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  handleClick(mode: any): void {
    this.activeMode = mode;
    console.log(`Selected Mode : ${mode}`);
    this.onModeChange.emit(`Selected Mode : ${mode}`);
  }
}
