import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Extent} from '../models/extent';

@Injectable({
  providedIn: 'root'
})
export class ScoreService extends ApiService {

  init(): void {
    this.endPoint = 'score';
  }

  getScore(zoom: number, extent: Extent): any {
    const bbox = Object.entries(extent).map((key, value) => {
      return key[1];
    }).join('%2C');

    return this.get(`?zoom=${zoom}&bbox=${bbox}`);
  }
}
