import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MapService} from './map.service';


@Injectable({
  providedIn: 'root'
})
export class NavigationHistoryService {

  constructor(private mapService: MapService,
              private route: ActivatedRoute) {
    this.route.queryParams
      .subscribe(params => {
          const {state, district, data} = params;
          if (state) {
            this.mapService.loadStates(params.state);
          }
          else if (district) {
            this.mapService.loadGrid();
          }
          else if (data) {
            const latLng = data.split(',');
            this.mapService.isochroneView(latLng[0], latLng[1]);
          } else {
            this.mapService.loadStates();
          }
        }
      );

  }
}
