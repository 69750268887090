import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {forkJoin} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {MapService} from '../../core/services/map.service';
import {CityService} from '../../core/services/city.service';
import {ScoreService} from '../../core/services/score.service';
import {City} from '../../core/models/city';


@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit {
  /**
   * Block Heading
   *
   * @required
   */
  @Input()
    heading = 'Examples';

  /**
   * Optional search handler
   */
  @Output()
    search = new EventEmitter<any>();

  cities: City[] = [];
  isLoaded = false;

  constructor(private cityService: CityService,
              private scoreService: ScoreService,
              private mapService: MapService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cityService.suggestions().pipe(
      mergeMap((result: any) => {
        const allScores = result.map((city: any) => this.scoreService.getScore(14, city.extent));
        return forkJoin(...allScores).pipe(
          map((scoreDataArray) => {
            if (result.length > 0) {
              result.forEach((city: any, index: number) => {
                city.score = scoreDataArray[index][0].score;
              });
              this.cdRef.detectChanges();
              return result;
            }
          })
        );
      })
    ).subscribe((data: any) => {
      this.cities = data;
      this.isLoaded = true;
      this.cdRef.detectChanges();
    });
  }

  loadExample(city: City): void {
    this.mapService.loadGrid();
    this.search.emit(city);
  }
}
