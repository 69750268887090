<div class="text-block" [class.has-background]="hasBackground" [class.large-text]="!address" [class.isAddress]="address">
  <ng-container *ngIf="!address">
    <h1 *ngIf="heading">{{heading}}</h1>
    <div class="text">{{content}}</div>
  </ng-container>
  <ng-container *ngIf="address">
    <h1>{{address.name}}</h1>
    <h2>{{address.plzCode}}</h2>
    <ng-container *ngIf="address">During the day, “{{address.name}}” achieves a mobility score of {{address.score.score}}. That’s just a mediocre number.</ng-container>
    <app-score [score]="address.score.score" [hasBorder]="true" size="large"></app-score>
  </ng-container>
</div>
