import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';

const apiUrl = environment.AppConfig.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public endPoint?: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    })
  };

  constructor(private http: HttpClient) {
    this.init();
  }

  init(): void {
    this.endPoint = 'cities';
  }

  getAll(): Observable<any> {
    return this.http.get(`${apiUrl}/${this.endPoint}`, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  get(path: any): Observable<any> {
    return this.http.get(`${apiUrl}/${this.endPoint}${path}`);
  }

  findState(path: string): any {
    return this.http.get(`assets/json/districts/${path}.geojson`, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  handleError(error: any): any {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  loadFile(file: string): any {
    return this.http.get(`assets/json/${file}`, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  loadSvg(file: string): any {
    return this.http.get(`assets/images/${file}.svg`, { responseType: 'text' })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
}
