<a
  [href]="href"
  class="btn" [class.button]="hasBackground || !anchor"
  *ngIf="anchor">
  {{text}}
  <i class="icon triply-arrow-right"></i>
</a>
<button type="submit" class="btn" [class.button]="hasBackground || !anchor" (click)="onClick.emit($event)" *ngIf="!anchor">
  {{text}}
  <i class="icon triply-arrow-right"></i>
</button>
