import {cartocolors} from './cartocolors';

export const colors = [
  '#E65639',
  '#F2A230',
  '#F2C230',
  '#29CC7A',
  '#82D957'
];

export const getColor = (index: number): any => {
  if (index < colors.length) {
    return colors[index];
  } else {
    return colors[0];
  }
};

export const scoreToColor = (score = 1): any => {
  let r, g, b = 0;
  if (score < 1) {
    score *= 10;
  }
  if (score < 50) {
    r = 255;
    g = Math.round(5.1 * score);
  } else {
    g = 255;
    r = Math.round(510 - 5.10 * score);
  }
  let h = r * 0x10000 + g * 0x100 + b * 0x1;
  return '#' + ('000000' + h.toString(16)).slice(-6);
};


const timeColorScheme = cartocolors.OrYel as any;

export class TimeColorLabel {
  constructor(public color: string, public time: number) {
  }
}

export const timeToColor = (travelTime: number, minTime: number, stepSize: number, maxTime: number): string => {
  const totalTimes = (maxTime - minTime) / stepSize + 1;
  const index = (travelTime - minTime) / stepSize;
  const colorScheme = timeColorScheme[`${totalTimes}`];
  return colorScheme[index];
};

function range(start: number, end: number, step: number): number[] {
  return Array((end - start) / step + 1).fill(0.0).map((_, idx) => start + step * idx);
}

export const timeColorLabels = (minTime: number, maxTime: number, stepSize: number): TimeColorLabel[] => {
    const times = range(minTime, maxTime, stepSize);
    return times.map((time, index) => new TimeColorLabel(timeColorScheme[`${times.length}`][index], time));
  }
;
