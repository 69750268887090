import {Component} from '@angular/core';
import {TranslationService} from './core/services/translation.service';
import {NavigationHistoryService} from './core/services/navigation-history.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'MobilityNavigator';

  constructor(private translationService: TranslationService,
              private navigationHistory: NavigationHistoryService) {
    this.translationService.init('en');
  }
}
