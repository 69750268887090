import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MapService} from '../../core/services/map.service';
import {City} from '../../core/models/city';
import {cartocolors} from '../../core/helpers/cartocolors';

@Component({
  selector: 'app-floating-box',
  templateUrl: './floating-box.component.html',
  styleUrls: ['./floating-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingBoxComponent implements OnInit, OnDestroy {
  address!: City;
  isSearched = false;
  isLoaded = false;
  isOpen = false;
  currentView!: string;
  private subscription!: Subscription;


  isochroneColorSchema = {
    colors: cartocolors.OrYel,
    values: [20, 40, 60, 80, 100, 120]
  };

  scoreColorSchema = {
    colors: ['#1fac78', '#82d957', '#f2c230', '#f2a230'],
    values: ['Great', 'Good', 'Medium', 'Low']
  };

  links = [
    {
      label: 'How is the score calculated?',
      href: '#'
    },
    {
      label: 'Measures for improvement',
      href: '#'
    }
  ];

  constructor(private mapService: MapService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.subscription = this.mapService.getCurrentView().subscribe((state) => {
      this.isLoaded = true;
      this.currentView = state;
      if (this.currentView !== 'overview') {
        this.isSearched = true;
      } else {
        this.isSearched = false;
      }
      this.cdRef.detectChanges();
    });

    this.mapService.currentAddress.subscribe((address) => {
      this.address = address;
      this.cdRef.detectChanges();
    });
  }

  handleSearch(city: City): void {
    this.isSearched = true;
    this.address = city;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleSidebar(): void {
    this.isOpen = !this.isOpen;
  }
}
