import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-examples',
  templateUrl: './examples.component.html',
  styleUrls: ['./examples.component.scss']
})
export class ExamplesComponent implements OnInit {
  /**
   * Block Heading
   *
   * @required
   */
  @Input()
    heading = 'Examples';

  constructor() { }

  ngOnInit(): void {
  }

}
