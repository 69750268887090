import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {fromEvent} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter, tap
} from 'rxjs/operators';
import {CityService} from '../../core/services/city.service';
import {City} from '../../core/models/city';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnInit {
  results!: City[];

  @Output()
    onSearch = new EventEmitter<any>();

  @ViewChild('searchBar', {static: true}) searchBar!: ElementRef;

  constructor(private cityService: CityService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    fromEvent(this.searchBar.nativeElement,'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((event:any) => {
          this.cityService.find(this.searchBar.nativeElement.value).subscribe((cities: City[]) => {
            this.results = cities;
            this.cdRef.detectChanges();
          });
        })
      )
      .subscribe();
  }

  handleAddressChange(city: any): void {
    city.score = {
      score: 8.4
    };
    this.onSearch.emit(city);
    this.resetInput();
  }

  resetInput(): void {
    const input = this.searchBar.nativeElement as HTMLInputElement;
    input.value = '';
    this.searchBar.nativeElement.value = '';
    this.results = [];
    this.cdRef.detectChanges();
  }
}
