import {Component, AfterViewInit, Input, OnDestroy} from '@angular/core';
import {latLng, Map, MapOptions} from 'leaflet';
import {MapService} from '../../core/services/map.service';
import {MapConfig} from '../../core/helpers/mapConfig';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements AfterViewInit, OnDestroy {
  public map: any;

  @Input()
    latitude = 47.6928624;
  @Input()
    longitude = 12.2247866;
  @Input()
    zoom = 8;

  options: MapOptions = {
    zoom: this.zoom,
    center: latLng(this.latitude, this.longitude),
    zoomControl: false,
    layers: [
      MapConfig.tileLayerWithAttribution()
    ]
  };

  constructor(private mapService: MapService) {}

  ngAfterViewInit(): void { }

  onMapReady(map: Map): void {
    this.map = map;
    this.mapService.initMap(this.map);
  }

  ngOnDestroy(): void {
    //this.map.remove();
  }
}
