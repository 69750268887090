import {
  Injectable,
  Injector,
} from '@angular/core';
import {createCustomElement, WithProperties, NgElement} from '@angular/elements';
import {Router} from '@angular/router';
import {Map, control, geoJSON, featureGroup, layerGroup, divIcon, marker, Layer} from 'leaflet';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ApiService} from './api.service';
import {ScoreComponent} from '../../components/score/score.component';
import {scoreToColor, timeToColor} from '../helpers/color';

declare var L: any;


@Injectable({
  providedIn: 'root'
})
export class MapService {
  public map?: any;
  layerGroups = featureGroup();
  scoreMarkers = layerGroup();
  markersGroup = layerGroup();
  public viewChange: Subject<any> = new BehaviorSubject('overview');
  private geoJson: any;
  private currentState!: string;
  private address = {
    name: 'Hartberg',
    score: {
      score: 7.2
    },
    plzCode: 8230
  };
  public currentAddress: Subject<any> = new BehaviorSubject(this.address);

  constructor(private apiService: ApiService,
              private injector: Injector,
              private router: Router) {
    if (!customElements.get('app-score-marker')) {
      // only define custom element for score marker if not yet present
      const ScoreElement = createCustomElement(ScoreComponent, {
        injector: this.injector
      });
      customElements.define('app-score-marker', ScoreElement);
    }
  }

  initMap(map: Map): void {
    this.map = map;
    this.layerGroups.addTo(this.map);
    this.scoreMarkers.addTo(this.map);
    this.markersGroup.addTo(this.map);

    control.zoom({
      position: 'topright',
      zoomInText: '<i class="triply-plus"></i>',
      zoomOutText: '<i class="triply-minus"></i>'
    }).addTo(this.map);

    this.addBackBtn();
  }

  addBackBtn(): void {
    let container: any;
    L.Control.Back = L.Control.extend({
      options: {
        position: 'topright',
      },
      onAdd: (map: Map) => {
        container = L.DomUtil.create('div', 'triply-control-back');
        const anchor = L.DomUtil.create('i', 'triply-back', container);
        L.DomEvent.addListener(container, 'click', this.handleBackBtn, this);
        L.DomEvent.disableClickPropagation(container);
        return container;
      },
      onRemove: (map: Map) => {
        L.DomEvent.removeListener(container, 'click', this.handleBackBtn, this);
      }
    });

    L.control.back = (opts: any) => {
      return new L.Control.Back(opts);
    };
    L.control.back().addTo(this.map);
  }

  handleBackBtn(): void {
    window.history.back();
  }

  loadGrid(): void {
    this.clearMap();
    this.apiService.loadFile('harberg.scores.geojson').subscribe((collection: any) => {
      this.setAddress(this.address, 8230);
      this.viewChange.next('districtDetail');
      geoJSON(collection, {
        onEachFeature: (feature: any, layer: Layer) => {
          layer.on('click', () => {
            this.router.navigate([], {queryParams: {data: `14,48.1`, zoom: 14}});
          });
        }
      }).addTo(this.layerGroups);
      this.setStyle();
    });
  }

  isochroneView(lat: number, lng: number, maxTime = 100): void {
    this.clearMap();
    this.apiService.loadFile('response.json').subscribe((json: any) => {
      this.setAddress(this.address, 8230);
      this.map?.setView([lat, lng], 12);
      this.viewChange.next('isochrone');
      this.geoJson = json.features.reverse();
      geoJSON(this.geoJson).addTo(this.layerGroups);
      this.setStyle();
    });
  }

  addScoreMarker(coordinate: any, score: number, size = 'medium'): void {
    const scoreEl: NgElement & WithProperties<ScoreComponent> = document.createElement('app-score-marker') as any;
    scoreEl.score = Number((score / 10).toFixed(1));
    scoreEl.size = size;

    const myMarker = divIcon({
      className: 'map-marker',
      html: scoreEl
    });
    marker(coordinate, {icon: myMarker}).addTo(this.scoreMarkers);
  }

  placeMarker(lat: number, lng: number, icon: string): void {
    const myMarker = divIcon({
      className: 'map-marker marker-color-gray a-class',
      html: `<div class="place-icon"><i class="icon triply-${icon}"></i></div>`
    });
    marker([lat, lng], {icon: myMarker}).addTo(this.markersGroup);
  }

  loadStates(path = 'states'): void {
    this.clearMap();
    const controller = this;
    this.apiService.findState(path).subscribe((collection: any) => {
      if (path === 'states') {
        this.viewChange.next('overview');
      } else {
        this.viewChange.next('stateDetail');
      }
      geoJSON(collection, {
        onEachFeature: (feature: any, layer: Layer) => {
          controller.onEachFeature(feature, layer, controller);
        }
      }).addTo(this.layerGroups);
      this.setStyle();
    });
  }

  onEachFeature(feature: any, layer: Layer, controller: any): void {
    layer.on({
      click: () => {
        if (feature.properties.stateId) {
          const state = feature.properties.stateId;
          this.setAddress(feature.properties, 4040);
          if (this.currentState !== state) {
            this.currentState = state;
            this.router.navigate([], {queryParams: {state}});
          }
          if (feature.properties.districtId) {
            this.router.navigate([], {queryParams: {district: feature.properties.districtId}});
          }
        }
      }
    });
  }

  getCurrentView(): Observable<any> {
    return this.viewChange.asObservable();
  }

  setAddress(data: any, plzCode: number): void {
    this.address.name = 'Hartberg';
    if (data.stateName) {
      this.address.name = data.stateName;
      if (data.districtName) {
        this.address.name = data.districtName;
      }
    }
    this.address.plzCode = plzCode;
    this.currentAddress.next(this.address);
  }

  fitMap(): void {
    if (this.layerGroups.getLayers().length > 0) {
      this.map.fitBounds(this.layerGroups.getBounds());
    }
  }

  private clearMap(): void {
    this.layerGroups.clearLayers();
    this.scoreMarkers.clearLayers();
    this.markersGroup.clearLayers();
  }

  private setStyle(maxTime = 120, minTime = 20, stepSize = 20): void {
    this.fitMap();
    let scoreSize = 'medium';
    let currentView: string;
    this.getCurrentView().subscribe((view) => {
      currentView = view;
      if (view === 'districtDetail') {
        scoreSize = 'small';
      }
    });
    this.layerGroups.eachLayer((layerGroup: any) => {
      layerGroup.eachLayer((layer: any) => {
        let score: any;
        if (layer.feature.properties.score) {
          // we're in one of the views that show the score
          score = layer.feature.properties.score * 10;
          if (score < 10) {
            score *= 10;
          }

          layer.setStyle({
            opacity: 0,
            fillColor: scoreToColor(score),
            fillOpacity: 0.4
          });
        }

        if (layer.feature.properties.time) {
          // we're in the isochrone view
          const time = layer.feature.properties.time;

          layer.setStyle({
            opacity: 0,
            fillColor: timeToColor(time, minTime, stepSize, maxTime),
            fillOpacity: 0.4
          });
        }

        if (currentView === 'districtDetail') {
          layer.setStyle({
            opacity: 1,
            color: 'white',
            weight: 1
          });
        }
        layer.on('mouseover', (e: Event) => {
          layer.setStyle({
            fillOpacity: 0.6
          });
        });
        layer.on('mouseout', (e: Event) => {
          layer.setStyle({
            fillOpacity: 0.4
          });
        });

        this.addScoreMarker(layer.getBounds().getCenter(), score, scoreSize);
      });
    });
  }
}
