<div id="contact-container" [class.full-width]="fullWidth">
  <div class="form-container col">
    <form [formGroup]="contactForm" *ngIf="!mailSent">
      <h1 [translate]="'Contact.GetStarted'"></h1>
      <h2 [class.secondary-color]="true" [translate]="'Contact.SubHeading'"></h2>
      <div class="form-group">
        <label for="firstname">{{'Contact.Form.FirstName' | translate}}</label>
        <input type="text" class="form-control" formControlName="firstname" id="firstname">
        <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
          <div *ngIf="f.firstname.errors.required">{{'Contact.Form.FirstName' | translate}} {{'Contact.Form.Required' | translate}}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="name">{{'Contact.Form.LastName' | translate}}</label>
        <input type="text" class="form-control" formControlName="name" id="name">
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
          <div *ngIf="f.name.errors.required">{{'Contact.Form.LastName' | translate}} {{'Contact.Form.Required' | translate}}</div>
        </div>
      </div>
      <hr>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" formControlName="email" id="email">
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">{{'Contact.Form.Email' | translate}} {{'Contact.Form.Required' | translate}}</div>
          <div *ngIf="f.email.errors.pattern">{{'Contact.Form.InvalidEmail' | translate}}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="work">{{'Contact.Form.Work' | translate}}</label>
        <input type="text" class="form-control" formControlName="work" id="work" placeholder="Mustermann">
        <div *ngIf="submitted && f.work.errors" class="invalid-feedback">
          <div *ngIf="f.work.errors.required">{{'Contact.Form.Work' | translate}} {{'Contact.Form.Required' | translate}}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="state">{{'Contact.Form.State' | translate}}</label>
        <input type="text" formControlName="state" id="state" placeholder="Austria">
        <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
          <div *ngIf="f.state.errors.required">{{'Contact.Form.State' | translate}} {{'Contact.Form.Required' | translate}}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="company">{{'Contact.Form.Company' | translate}}</label>
        <input type="text" formControlName="company" id="company" placeholder="Muster GmbH">
        <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
          <div *ngIf="f.company.errors.required">{{'Contact.Form.Company' | translate}} {{'Contact.Form.Required' | translate}}</div>
        </div>
      </div>

      <div class="form-group full-width">
        <label for="message">IAM</label>
        <input type="text" class="form-control" formControlName="message" id="message" placeholder="interested in the Mobility Atlas.">
      </div>

      <app-button [text]="'Contact.Form.Submit' | translate" (onClick)="onSubmit()" [hasBackground]="true"></app-button>
    </form>

    <div class="success-message" *ngIf="mailSent">
      <h1 [translate]="'Contact.Hello'" [translateParams]="{name: f.firstname.value}"></h1>
      <h2 [translate]="'Contact.SubHeading'"></h2>
      <p class="text" [translate]="'Contact.Text'"></p>
      <div class="links">
        <app-button [text]="'Contact.VisitFlow' | translate" href="#"></app-button>
        <app-button [text]="'Contact.TransitManagement' | translate" href="#"></app-button>
      </div>
      <app-button [text]="'Contact.BackToMap' | translate" href="/" [hasBackground]="true"></app-button>
    </div>
  </div>
  <div class="col info">
    <img src="assets/images/bus.png" title="Bus"/>
    <div class="content">
      <h2 [class.secondary-color]="true" [translate]="'Contact.Informations.Heading'"></h2>
      <ul>
        <li [translate]="'Contact.Informations.Communicate'"></li>
        <li [translate]="'Contact.Informations.AssignTasks'"></li>
        <li [translate]="'Contact.Informations.Integrate'"></li>
        <li [translate]="'Contact.Informations.EngagedWorkforce'"></li>
        <li [translate]="'Contact.Informations.Suggestions'"></li>
      </ul>
      <div class="bottom-text" [class.secondary-color]="true">
        <h3 class="call-us">
          <a href="tel:+43-123456789">
            <i class="triply-call"></i>
            <span>{{'Contact.Informations.CallUs' | translate}}</span>
            <i class="triply-arrow-right"></i>
          </a>
        </h3>
        <p [translate]="'Contact.Informations.Text'"></p>
      </div>
    </div>
  </div>
</div>
