<div id="suggestions" *ngIf="cities.length>0 && isLoaded">
  <h3 class="heading">{{heading}}</h3>
  <div class="suggestion-item" *ngFor="let city of cities; let i = index" (click)="loadExample(city)">
    <app-score [score]="city.score" [hasBorder]="true"></app-score>
    <h4 class="suggestion-link">
      {{city.name}}
      <i class="triply-arrow-right"></i>
    </h4>
  </div>
</div>
