<header class="navbar" [class.is-active]="isActive">
  <div class="navbar-inner">
    <div id="logo">
      <img src="assets/images/logo.png" />
    </div>
    <button class="toggle-menu" (click)="toggleMenu()">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </button>
    <div class="menu-wrapper">
      <button class="close-menu" (click)="toggleMenu()">
        <span>Close Menu</span>
      </button>
      <ul class="menu">
        <li *ngFor="let link of Links">
          <a *ngIf="!link.modalSelector" [href]="link.href" routerLinkActive="active">{{'Header.Menu.' + link.label | translate}}</a>
          <a *ngIf="link.modalSelector" (click)="openModal('contact-modal')" routerLinkActive="active">{{'Header.Menu.' + link.label | translate}}</a>
        </li>
      </ul>
      <app-button [text]="'Header.FindOut' | translate" href="#" [anchor]="true" [hasBackground]="true"></app-button>
    </div>
  </div>
</header>
