import { Injectable } from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PoiService extends ApiService {

  init(): void {
    this.endPoint = 'pois';
  }

  find(bbox: string): any {
    return this.get(`?bbox=${bbox}`);
  }

  load(): any {
    return this.loadFile('pois.geojson');
  }
}
