import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {

  /**
   * Button contents
   *
   * @required
   */
  @Input()
    label = 'Day';

  /**
   * Button contents
   *
   * @required
   */
  @Input()
    time = '7:00 – 19:00';

  @Input()
    active = false;

  @Output()
    onClick = new EventEmitter<any>();

  private activeMode = 'Day';

  constructor() { }

  ngOnInit(): void {}

  click(): void {
    this.activeMode = this.label;
    this.onClick.emit(this.activeMode);
  }

}
