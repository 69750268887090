import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {

  constructor() {
  }

  @Input() values: number[] | string[] = [];
  @Input() colors: string[] | [] | Map<number | string, string> | any = [];
  @Input() categorical = true;

  get exampleValues(): { value: string, color: string }[] {
    if (this.values.length === 0) {
      return [];
    }
    let schema: ColorScheme<any>;
    if (typeof this.values[0] === 'number'){
      schema = new CategoricalNumberColorScheme(this.values as number[], this.colors);
    } else {
      schema = new CategoricalColorScheme(this.values as string[], this.colors);
    }

    const categories = schema.getCategories();
    return Array.from(categories.keys()).map(key => ({value: `${key}`, color: categories.get(key) ?? ''}));
  }

  ngOnInit(): void {
  }

}

export interface ColorScheme<T> {
  getColor: (value: T) => string;
  getLabel: (value: T) => string;

  getCategories: () => Map<T, string>;
}

/*
 * @param schema The name of a CartoColors color scheme, a cartocolors color scheme or an array of colors the same size as values
 */
function extractColorArrayFromSchema(schema: any, values: Array<any> = []): Array<string> {
  let cartoColorScheme = schema;
  let colors = [];
  if (schema instanceof String) {
    // schema looks like a cartocolor schema name
    cartoColorScheme = schema;
  }
  if (cartoColorScheme['2'] instanceof Array && cartoColorScheme['3'] instanceof Array) {
    // this looks like a color schema from the cartocolors list
    colors = cartoColorScheme[`${values.length}`];
  } else {
    // this can also just be an array of colors of the same size as values
    colors = schema;
  }
  return colors;
}

export class CategoricalNumberColorScheme implements ColorScheme<number> {
  public categories: Map<number, string>;

  /**
   * Create a new categorical color scheme by combining given values and color data
   * @param values list of categorical values expected
   * @param schema The name of a CartoColors color scheme, a cartocolors color scheme or an array of colors the same size as values
   */
  constructor(values: number[], schema: any) {
    const colors = extractColorArrayFromSchema(schema, values);
    this.categories = new Map(values.map((val, index) => [val, colors[index]]));
  }

  /**
   * Construct a new CategoricalNumberColorScheme based on the min, max and step values passed.
   */
  static fromMinMax(schema: any, min: number, max: number, step: number = 1): CategoricalNumberColorScheme {
    const categories = Array((max - min) / step + 1).fill(0.0).map((_, idx) => min + step * idx);
    return new CategoricalNumberColorScheme(categories, schema);
  }

  getColor(value: number): string {
    return this.categories.get(value) ?? '';
  }

  getLabel(value: number): string {
    return `${value}`;
  }

  getCategories(): Map<number, string> {
    return this.categories;
  }

}

export class CategoricalColorScheme implements ColorScheme<string> {
  public categories: Map<string, string>;

  constructor(categories: Map<string, string> | Array<string>, schema: any = []) {
    if (categories instanceof Map) {
      this.categories = categories;
    } else {
      console.log(schema);
      const colors = extractColorArrayFromSchema(schema, categories);
      this.categories = new Map(categories.map((val, index) => [val, colors[index]]));
    }
  }

  getColor(value: string): string {
    return this.categories.get(value) ?? '';
  }

  getLabel(value: string): string {
    return value;
  }

  getCategories(): Map<string, string> {
    return this.categories;
  }
}
