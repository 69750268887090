<div class="floating-box" [class.isOpen]="isOpen" *ngIf="isLoaded">
  <div class="settings-btn" (click)="handleSidebar()">
    <i class="triply-settings"></i>
    <span>Settings</span>
  </div>
  <div class="settings-holder">

    <app-search-bar (onSearch)="handleSearch($event)"></app-search-bar>
    <app-text-block [heading]="'Welcome.Heading' | translate" [content]="'Welcome.Content' | translate"
                    [hasBackground]="isSearched" *ngIf="!isSearched"></app-text-block>
    <app-text-block [address]="address"
                    [hasBackground]="isSearched" *ngIf="isSearched && address"></app-text-block>
    <div class="content">
      <app-suggestions (search)="handleSearch($event)" *ngIf="!isSearched"></app-suggestions>
      <div id="links-container" *ngIf="isSearched">
        <app-button [text]="link.label" [href]="link.href" *ngFor="let link of links" [hasBackground]="false"
                    [anchor]="true"></app-button>
      </div>
      <app-toggle-container></app-toggle-container>
      <app-filters *ngIf="isSearched"></app-filters>
      <app-tip-block *ngIf="!isSearched"></app-tip-block>
      <app-legend *ngIf="currentView != 'overview'"
                  [categorical]="true"
                  [colors]="currentView === 'isochrone' ? isochroneColorSchema.colors : scoreColorSchema.colors"
                  [values]="currentView === 'isochrone' ? isochroneColorSchema.values : scoreColorSchema.values"></app-legend>

    </div>
    <button (click)="handleSidebar()" class="close-btn btn button">Close</button>
  </div>
</div>
