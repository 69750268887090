import {Component, OnInit} from '@angular/core';
import {ModalService} from '../modal';

@Component({
  selector: 'triply-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isActive = false;
  Links = [
    {
      label: 'MobilityFinder',
      href: '/',
    },
    {
      label: 'Contact',
      href: 'contact',
      modalSelector: 'contact'
    },
    {
      label: 'About',
      href: '#'
    }
  ];

  constructor(private modalService: ModalService) { }

  ngOnInit(): void { }

  openModal(id: string): void {
    this.modalService.open(id);
  }

  toggleMenu(): void {
    this.isActive = !this.isActive;
  }
}
