import { Injectable } from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CityService extends ApiService {

  init(): void {
    this.endPoint = 'cities';
  }

  find(city: string): any {
    return this.get(`/search?searchString=${city}`);
  }

  suggestions(): any {
    return this.get(`/suggestions`);
  }
}
