import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tip-block',
  templateUrl: './tip-block.component.html',
  styleUrls: ['./tip-block.component.scss']
})
export class TipBlockComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
