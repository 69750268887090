import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LeafletDirective, LeafletModule} from '@asymmetrik/ngx-leaflet';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {MapComponent} from './components/map/map.component';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import {ToggleButtonComponent} from './components/toggle-button/toggle-button.component';
import {TextBlockComponent} from './components/text-block/text-block.component';
import {ToggleContainerComponent} from './components/toggle-container/toggle-container.component';
import {TipBlockComponent} from './components/tip-block/tip-block.component';
import {ExamplesComponent} from './components/examples/examples.component';
import {FloatingBoxComponent} from './components/floating-box/floating-box.component';

import {TranslationService} from './core/services/translation.service';
import {FiltersComponent} from './components/filters/filters.component';
import {HomeComponent} from './components/home/home.component';
import {ButtonModule} from './components/button/button.module';
import {ApiService} from './core/services/api.service';
import {ModalModule} from './components/modal';
import {ContactModule} from './components/contact/contact.module';
import {SuggestionsComponent} from './components/suggestions/suggestions.component';
import {InlineSVGModule} from 'ng-inline-svg';
import {SharedModule} from './core/shared/shared.module';
import {ScoreComponent} from './components/score/score.component';
import {NavigationHistoryService} from './core/services/navigation-history.service';
import { LegendComponent } from './components/legend/legend.component';

export function translationInitializer(translationService: TranslationService) {
  return () => {
    return translationService.init('en');
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MapComponent,
    SearchBarComponent,
    ToggleButtonComponent,
    TextBlockComponent,
    ToggleContainerComponent,
    TipBlockComponent,
    ExamplesComponent,
    FloatingBoxComponent,
    FiltersComponent,
    HomeComponent,
    SuggestionsComponent,
    ScoreComponent,
    LegendComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    LeafletModule,
    InlineSVGModule.forRoot(),
    ContactModule,
    ButtonModule,
    ModalModule
  ],
  providers: [
    ApiService,
    LeafletDirective,
    {
      provide: APP_INITIALIZER,
      useFactory: translationInitializer,
      deps: [TranslationService],
      multi: true
    },
  ],
  exports: [],
  bootstrap: [AppComponent],
  entryComponents: [
    ScoreComponent
  ]
})
export class AppModule {
}
